//React
import React, { useEffect, useState } from 'react'
import api from '../../../../../services/api'
// //Components
import ImgBackplate from '../../../../../components/ImgBackplate'
import DeleteIcon from '../../../../../assets/icons/delete.svg'
import { useToast } from '../../../../../hooks/toast'
import Button from '../../../../../components/Button'
import Modal from '../../../../../components/Modal'
import Loading from '../../../../../components/Loading'
import { useTranslation } from 'react-i18next'

import {
  Field,
  Select,
  UserWrapper,
  UserContent,
  UserImg,
  UserInfo,
  UserName,
  UserContainer,
  UserPhrase,
  LeaderSelect,
  UserActions,
  UserPosition,
  LoadingContainer,
  Users
} from './styles'

interface Props {
  setModal: any
  teamData: any
  getTeam: Function
  isTeamActive: any
}

interface TeamUsers {
  user_id: number
  is_leader: boolean
  User: {
    name: string
    image: string
    phrase: string
  }
}

interface UsersList {
  id: number
  name: string
  image: string
  phrase: string
}

const ModalTeamMembers: React.FC<Props> = ({
  setModal,
  teamData,
  getTeam,
  isTeamActive
}) => {
  const { t } = useTranslation()

  const [onQuery, setOnQuery] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [isLeader, setIsLeader] = useState<boolean>()
  const [loggedId, setLoggedId] = useState<number>()

  const { addToast } = useToast()

  const [teamUsers, setTeamUsers] = useState<TeamUsers[]>([])
  const [userList, setUserList] = useState<UsersList[]>([])

  async function addUserInTeam(user: UsersList) {
    let check = teamUsers.filter((el: TeamUsers) => el.user_id === user.id)

    if (check.length > 0) {
      return
    }

    await api
      .post(
        '/team/add/user',
        {
          team_id: teamData.id,
          user_id: user.id
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('@token/iteams')
          }
        }
      )
      .then((res) => {
        setTeamUsers([
          ...teamUsers,
          {
            User: {
              image: user.image,
              name: user.name,
              phrase: user.phrase
            },
            user_id: user.id,
            is_leader: false
          }
        ])
        getTeam()
        addToast({
          type: res.data.Toast.type,
          title: t(res.data.Toast.title),
          description: t(res.data.Toast.description)
        })
      })
      .catch((err) => {
        setLoading(false)
        if (err.response) {
          addToast({
            type: err.response.data.Toast.type,
            title: t(err.response.data.Toast.title),
            description: t(err.response.data.Toast.description)
          })
        } else {
          addToast({
            type: 'error',
            title: t('Server'),
            description: t('CommunicationError')
          })
        }
      })
  }

  async function getTeamUsers() {
    await api
      .get(`/team/list/users/${teamData.id}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('@token/iteams')
        }
      })
      .then((res) => {
        setTeamUsers(res.data.getTeamUsers.UserTeams)
        setIsLeader(res.data.is_leader)
        setLoggedId(res.data.logged_user_id)
        setLoading(false)
      })
      .catch((err) => {
        if (err.response) {
          addToast({
            type: err.response.data.Toast.type,
            title: t(err.response.data.Toast.title),
            description: t(err.response.data.Toast.description)
          })
        } else {
          addToast({
            type: 'error',
            title: t('Server'),
            description: t('CommunicationError')
          })
        }
      })
  }

  async function getUsersList() {
    await api
      .get('/user/availablelist/all', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('@token/iteams')
        }
      })
      .then((res) => {
        setUserList(res.data.getUsersAvailable)
      })
      .catch((err) => {
        if (err.response) {
          addToast({
            type: err.response.data.Toast.type,
            title: t(err.response.data.Toast.title),
            description: t(err.response.data.Toast.description)
          })
        } else {
          addToast({
            type: 'error',
            title: t('Server'),
            description: t('CommunicationError')
          })
        }
      })
  }

  async function deleteUser(user_id: number) {
    setOnQuery(true)

    let newArray = teamUsers.filter((el: TeamUsers) => el.user_id !== user_id)

    await api
      .delete(`/team/${teamData.id}/delete/user/${user_id}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('@token/iteams')
        }
      })
      .then((res) => {
        setOnQuery(false)
        setTeamUsers(newArray)
        getTeam()
        addToast({
          type: res.data.Toast.type,
          title: t(res.data.Toast.title),
          description: t(res.data.Toast.description)
        })
      })
      .catch((err) => {
        setOnQuery(false)

        setOnQuery(false)
        if (err.response) {
          addToast({
            type: err.response.data.Toast.type,
            title: t(err.response.data.Toast.title),
            description: t(err.response.data.Toast.description)
          })
        } else {
          addToast({
            type: 'error',
            title: t('Server'),
            description: t('CommunicationError')
          })
        }
      })
  }

  async function changePosition(user_id: number, j: number, evt: string) {
    await api
      .put(
        `/team/${teamData.id}/changeposition/user/${user_id}`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('@token/iteams')
          }
        }
      )
      .then((res) => {
        getTeam()
        teamUsers[j].is_leader = evt === '1' ? true : false
        setTeamUsers([...teamUsers])
        addToast({
          type: res.data.Toast.type,
          title: t(res.data.Toast.title),
          description: t(res.data.Toast.description)
        })
      })
      .catch((err) => {
        setLoading(false)
        if (err.response) {
          addToast({
            type: err.response.data.Toast.type,
            title: t(err.response.data.Toast.title),
            description: t(err.response.data.Toast.description)
          })
        } else {
          addToast({
            type: 'error',
            title: t('Server'),
            description: t('CommunicationError')
          })
        }
      })
  }

  function Reset() {
    var dropDown = document.getElementById('select') as HTMLSelectElement
    dropDown.selectedIndex = 0
  }

  useEffect(() => {
    getTeamUsers()
    getUsersList()
    // eslint-disable-next-line
  }, [])

  return (
    <Modal
      title={t('Team')}
      setModal={() => {
        setModal(false)
      }}
    >
      {loading ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : (
        <>
          <Field>
            {(localStorage.getItem('@role/iteams') === 'admin' || isLeader) &&
              teamData.is_active && (
                <Select>
                  <select
                    name="role"
                    id="select"
                    defaultValue={'#'}
                    onChange={(evt: any) => {
                      let user = JSON.parse(evt.target.value)
                      addUserInTeam(user)
                      Reset()
                    }}
                  >
                    <option value="#" disabled>
                      {t('AddNewMember')}
                    </option>
                    {userList?.map((element, i: any) => {
                      return (
                        <option key={i} value={JSON.stringify(element)}>
                          {element.name}
                        </option>
                      )
                    })}
                  </select>
                </Select>
              )}
            <hr />
            <Users>
              {teamUsers.map((element, j: number) => {
                return (
                  <UserWrapper key={j}>
                    <UserContent>
                      <UserImg>
                        <ImgBackplate
                          image={element.User.image}
                          radius="100%"
                          width="44px"
                          height="44px"
                        />
                      </UserImg>

                      <UserInfo>
                        <UserContainer>
                          <UserName>
                            <h4>{element.User.name}</h4>
                          </UserName>

                          {((!(
                            isLeader === element.is_leader &&
                            loggedId === element.user_id
                          ) &&
                            isLeader) ||
                            localStorage.getItem('@role/iteams') === 'admin') &&
                          teamData.is_active ? (
                            <UserActions>
                              <LeaderSelect
                                backgroundColor={
                                  element.is_leader
                                    ? 'var(--color-orange )'
                                    : 'var(--color-blue-confirm )'
                                }
                              >
                                <select
                                  value={
                                    element.is_leader
                                      ? t('Leader')
                                      : t('Member')
                                  }
                                  onChange={(evt) => {
                                    changePosition(
                                      element.user_id,
                                      j,
                                      evt.target.value
                                    )
                                  }}
                                >
                                  {element.is_leader ? (
                                    <>
                                      <option value="1">{t('Leader')}</option>
                                      <option value="0">{t('Member')}</option>
                                    </>
                                  ) : (
                                    <>
                                      <option value="0">{t('Member')}</option>
                                      <option value="1">{t('Leader')}</option>
                                    </>
                                  )}
                                </select>
                              </LeaderSelect>
                              <Button
                                onClick={() => {
                                  deleteUser(element.user_id)
                                }}
                                title={t('Delete')}
                                icon={DeleteIcon}
                                gap="6px"
                                height="18px"
                                width="55px"
                                color="var(--color-orange)"
                                fontSize="10px"
                                disabled={onQuery}
                              />
                            </UserActions>
                          ) : (
                            <UserActions>
                              <UserPosition
                                isUserLeader={isLeader!}
                                isTeamActive={isTeamActive}
                                backgroundColor={
                                  element.is_leader
                                    ? 'var(--color-orange )'
                                    : 'var(--color-blue-confirm )'
                                }
                              >
                                <h6>
                                  {element.is_leader
                                    ? t('Leader')
                                    : t('Member')}
                                </h6>
                              </UserPosition>
                            </UserActions>
                          )}
                        </UserContainer>
                        <UserPhrase>
                          <h5 style={{ opacity: '0.6' }}>
                            {element.User.phrase}
                          </h5>
                        </UserPhrase>
                      </UserInfo>
                    </UserContent>
                  </UserWrapper>
                )
              })}
            </Users>
            <hr />
          </Field>
        </>
      )}
    </Modal>
  )
}

export default ModalTeamMembers
