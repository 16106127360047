import styled from 'styled-components'

export const Field = styled.div`
  height: 100%;
  width: 100%;

  > hr {
    opacity: 0.5;
  }

  @media (max-width: 768px) {
    height: auto;
  }
`

export const UserComments = styled.div`
  /* ::-webkit-scrollbar {
    display: none;
  } */
  max-height: 40vh;
  min-height: 40vh;
  overflow-y: auto;
  width: 100%;
  > h4 {
    padding-top: 6px;
  }

  @media (max-width: 768px) {
    max-height: 80vh;
    min-height: 80vh;
  }
`

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  justify-content: flex-start;
  align-items: flex-start;
`

export const UserData = styled.div`
  display: flex;

  gap: 6px;
  width: 100%;

  > h4 {
    width: 100%;
  }

  > h6 {
    opacity: 0.5;
    margin-bottom: 6px;
  }
`

export const Info = styled.div`
  > h6 {
    opacity: 0.5;
    margin-bottom: 6px;
  }
`

export const UserReview = styled.div`
  margin-left: 30px;

  > h5 {
    word-break: break-word;
  }
`

export const NoComment = styled.div`
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
  max-height: 40vh;
  min-height: 40vh;
  overflow-y: auto;
  > h4 {
    padding-top: 6px;
  }

  @media (max-width: 768px) {
    max-height: 60vh;
    min-height: 60vh;
  }
`
