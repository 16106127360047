import Close from "../../assets/img/close.svg"
import { Title } from "../Modal/styles";
import { MessageContainer, MessageText, MessageContainerHeader, MessageContainerMain, MessageContainerContent } from "./styles";

interface Props {
  title: string
  onClose: Function
  children?: any
}

const MessageAnimation: React.FC<Props> = ({ title, onClose, children = null }) => {
  return (
    <MessageContainer>
      <MessageContainerHeader>
        <Title>
          <img
            src={Close}
            alt="Fechar"
            onClick={() => onClose()}
          />
        </Title>
      </MessageContainerHeader>

      <MessageContainerMain>
        <MessageText>{title}</MessageText>
      </MessageContainerMain>

      <MessageContainerContent>
        {children}
      </MessageContainerContent>
    </MessageContainer>
  );
};

export default MessageAnimation;