import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// Importing translation files

import translationPT from './languages/pt-br.json'
import translationEN from './languages/en-us.json'
import translationES from './languages/es-es.json'

//Creating object with the variables of imported translation files
const resources = {
  pt_BR: {
    translation: translationPT
  },
  en_US: {
    translation: translationEN
  },
  es_ES: {
    translation: translationES
  }
}

//i18N Initialization

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('@language/iteams')
    ? localStorage.getItem('@language/iteams')?.toString()
    : 'pt_BR', //default language
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
})

export default i18n
