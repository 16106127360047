import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: var(--color-background);
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const ContainerContent = styled.div`
min-height: 100vh;
  padding: 24px 24px 24px 30px;
  overflow: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 24px 12px 24px 12px;
  }
`;
export const TopBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-end;
  }
`;
export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  width: 80%;

  > h1 {
    margin-left: 24px;

    word-wrap: break-word;
    font-size: 28px;
    margin-right: 24px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-end;
      margin-right: 0px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
    word-break: break-all;
    > h1 {
      width: 100%;
      margin-left: 0;
    }
  }
`;
export const RightSide = styled.div`
  display: flex;
  align-items: center;
  > h3 {
    margin-right: 12px;
    opacity: 0.5;
  }

  @media (max-width: 768px) {
    justify-content: space-between;
    width: 100%;
    margin-top: 6px;
  }
`;
export const ReturnContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;
  &:hover {
    transition: 0.25;
    opacity: 0.7;
  }

  > h3 {
    opacity: 0.5;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
export const ImgContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 6px;

  @media (max-width: 768px) {
    display: none;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  height: fit-content;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

  .nodata {
    opacity: 0.4;
    width: 60%;
    height: fit-content;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-45%, -50%);
  }
`;
export const Plus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-blue-info);
  width: 44px;
  height: 44px;
  clip-path: circle();
  color: var(--color-blue-confirm);
  cursor: pointer;
  &:hover {
    transition: 0.25;
    opacity: 0.7;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
export const PlusPlus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-blue-info);
  width: 44px;
  height: 44px;
  clip-path: circle();
  color: var(--color-blue-confirm);
  cursor: pointer;
  &:hover {
    transition: 0.25;
    opacity: 0.7;
  }
`;
export const PlusMobile = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  background-color: var(--color-blue-info);
  width: 44px;
  height: 44px;
  clip-path: circle();
  color: var(--color-blue-confirm);

  &:hover:not(:disabled) {
    transition: 0.25;
    opacity: 0.7;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;
export const OnScreenImg = styled.div`
  &:hover {
    transition: 0.25;
    opacity: 0.7;
  }
`;
export const ReturnContainerMobile = styled.div`
  cursor: pointer;
  align-items: center;
  width: fit-content;
  display: none;
  @media (max-width: 768px) {
    display: flex;
  }
`;
export const MemberButtons = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;
export const Graph = styled.div`
  border-radius: 10px;
  background-color: white;
  padding: 30px;
  width: 100%;

  > h4 {
    opacity: 0.5;
  }

  > .web {
    width: 100%;
  }

  > .mobile {
    display: none;
  }

  @media (max-width: 768px) {
    > .web {
      display: none;
    }

    > .mobile {
      width: 100%;
      display: flex;
    }
  }
`;
export const TableHead = styled.div`
  margin-top: 24px;
  width: 100%;

  & h4 {
    opacity: 0.5;
  }

  > ul {
    list-style-type: none;
    display: flex;
    margin-top: 12px;
    > li {
      :nth-child(1) {
        display: flex;
        align-items: center;
        flex: 3;
        padding-left: 30px;

        @media (max-width: 768px) {
          flex: 2;
        }
      }
      :nth-child(2) {
        display: flex;
        align-items: center;
        flex: 1;
        @media (max-width: 768px) {
          display: none;
        }
      }
      :nth-child(3) {
        display: flex;
        align-items: center;
        flex: 1;
        @media (max-width: 768px) {
          display: none;
        }
      }
      :nth-child(4) {
        display: flex;
        align-items: center;
        flex: 1;
        @media (max-width: 768px) {
          display: none;
        }
      }

      :nth-child(5) {
        display: flex;
        align-items: center;
        flex: 1;
        @media (max-width: 768px) {
          display: none;
        }
      }

      :nth-child(6) {
        display: flex;
        align-items: center;
        flex: 1;
        @media (max-width: 768px) {
          display: none;
        }
      }

      :nth-child(7) {
        display: flex;
        align-items: center;
        flex: 1.4;
      }
    }
  }
`;
export const TableContent = styled.div`
  width: 100%;
  margin-top: 6px;
  background-color: var(--color-white);
  border-radius: 10px;
  padding: 12px 0px;
  cursor: pointer;

  > ul {
    list-style-type: none;
    display: flex;

    > li {
      :nth-child(1) {
        display: flex;
        align-items: center;
        flex: 3;
        padding-left: 30px;
        > h4 {
          width: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 16px;
        }

        @media (max-width: 768px) {
          flex: 2;
        }
      }
      :nth-child(2) {
        display: flex;
        align-items: center;
        flex: 1;
        @media (max-width: 768px) {
          display: none;
        }
      }
      :nth-child(3) {
        display: flex;
        gap: 8px;
        align-items: center;
        flex: 1;
        @media (max-width: 768px) {
          display: none;
        }
      }

      :nth-child(4) {
        display: flex;
        align-items: center;
        flex: 1;

        > h4 {
          width: 85%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        @media (max-width: 768px) {
          display: none;
        }
      }
      :nth-child(5) {
        flex: 1;
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
          display: none;
        }
      }

      :nth-child(6) {
        flex: 1;
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
          display: none;
        }
      }

      :nth-child(7) {
        flex: 1.4;
        display: flex;
        align-items: center;
      }
    }
  }
`;
export const ContentFooter = styled.div`
  cursor: pointer;
  width: fit-content;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 6px;

  > h3 {
    color: var(--color-blue-confirm);
    margin-left: 6px;
    line-height: 15px;
  }

  &:hover {
    transition: 0.25;
    opacity: 0.7;
  }
`;
