const colors = {
  buttonPrimary: 'var(--color-orange)',
  buttonDanger: 'var(--color-red)',
  buttonSuccess: 'var(--color-green)',
  buttonConfirm: 'var(--color-blue-confirm)',
  buttonTransparent: 'transparent',
  buttonText: '#000',
  white: 'var(--color-white)',
  blue: '#2A4494'
}

export default colors
