import styled from 'styled-components'

export const Field = styled.div`
  /* margin-bottom: 24px; */

  > h3 {
    margin-bottom: 12px;
  }
`

export const Select = styled.div`
  width: 100%;
  margin-bottom: 12px;
  padding: 0px 16px;
  background-color: var(--color-gray);
  border-radius: 10px;

  > select {
    cursor: pointer;
    width: 100%;
    height: 50px;
    border: none;
    outline: 0;
    border-radius: 10px;

    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    display: flex;
    align-items: center;
    background-color: var(--color-gray);
  }
`
export const UserWrapper = styled.div`
  margin-top: 12px;
`

export const UserContent = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`
export const UserImg = styled.div`
  width: fit-content;
  height: fit-content;
  margin-right: 6px;
`
export const UserInfo = styled.div`
  width: 100%;
`

export const UserName = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    > h4 {
      width: 70%;
      margin-right: 6px;
      word-break: break-word;
    }
  }
`

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 6px;

  > h4 {
    margin-right: 6px;
  }
`
export const UserPhrase = styled.div`
  > h5 {
    max-width: 95%;
  }
`

export const LeaderSelect = styled.div<{ backgroundColor: string }>`
  width: 80px;
  height: 25px;
  border-radius: 6px;
  background-color: var(--color-orange);
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor};`}
  padding: 0px 6px;

  > h6 {
    color: var(--color-white);
  }

  > select {
    cursor: pointer;

    color: var(--color-white);
    width: 100%;
    height: 100%;
    border: none;
    outline: 0;
    border-radius: 6px;

    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;

    display: flex;
    align-items: center;
    background-color: transparent;

    > option {
      color: var(--color-black);
    }
  }
`

export const UserActions = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  padding-right: 12px;
`

export const UserPosition = styled.div<{
  backgroundColor: string
  isUserLeader: boolean
  isTeamActive: boolean
}>`
  width: 80px;
  height: 25px;
  border-radius: 6px;
  background-color: var(--color-orange);

  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor};`}

  margin-right: ${({ isUserLeader, isTeamActive }) =>
    isUserLeader && isTeamActive && '73px'};

  padding: 0px 6px;

  > h6 {
    color: var(--color-white);
    width: 100%;
    height: 100%;
    border: none;
    outline: 0;
    border-radius: 6px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`
export const LoadingContainer = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;

  @media (max-width: 768px) {
    margin-left: -5px;
  }
`
export const Users = styled.div`
  max-height: 40vh;
  min-height: 40vh;
  overflow-y: auto;
  padding-bottom: 12px;

  @media (max-width: 768px) {
    max-height: 60vh;
    min-height: 60vh;
  }
`
