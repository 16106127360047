import React from "react";

import { Img } from "./styles";

export interface Props {
  /**
   *  Aplica a propriedade Width
   */
  width?: string;
  /**
   *  Aplica a propriedade Height
   */
  height?: string;
  /**
   *  Aplica a propriedade Border-Radius
   */

  radius?: string;
  /**
   *  Aplica a propriedade Margin
   */
  margin?: string;
  /**
   *  Aplica a propriedade Rotate
   */
  rotate?: string;

  /**
   *  Aplica a propriedade Border-Radius-Rigth
   */
  radiusRight?: string;
  /**
   *  Aplica a propriedade Border-Radius-Left
   */
  radiusLeft?: string;

  /**
   *  Aplica a propriedade Border
   */
  borderColor?: string;
  /**
   *  Recebe a imagem
   */
  image: any;

  cursor?: string;

  onClick?: any;
}

const ImgBackplate: React.FC<Props> = ({
  image,
  borderColor,
  width,
  height,
  radius,
  radiusRight,
  radiusLeft,
  margin,
  rotate,
  cursor,
  onClick,
}) => {
  return (
    <Img
      image={image}
      width={width}
      height={height}
      margin={margin}
      radius={radius}
      radiusLeft={radiusLeft}
      radiusRight={radiusRight}
      borderColor={borderColor}
      rotate={rotate}
      cursor={cursor}
      onClick={onClick}
    />
  );
};

export default ImgBackplate;
