import styled from 'styled-components'

import { Props } from '.'

export const Img = styled.div<Props>`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${({ image }) => image && ` background-image: url(${image});`}
  ${({ margin }) => margin && `margin:${margin}; `}
  ${({ width }) => width && `width:${width};  min-width: ${width}; `}
  ${({ height }) => height && `height:${height};  min-height: ${height}; `}
  ${({ rotate }) => rotate && `transform: rotate(${rotate});`}
  ${({ radius }) => radius && `border-radius:${radius};`}
  ${({ radiusLeft }) =>
    radiusLeft &&
    `border-bottom-left-radius:${radiusLeft}; border-top-left-radius:${radiusLeft}; `}
     ${({ radiusRight }) =>
    radiusRight &&
    `border-bottom-right-radius:${radiusRight}; border-top-right-radius:${radiusRight}; `}

  ${({ borderColor }) => borderColor && `border: 2px solid ${borderColor};`}
  ${({ cursor }) => cursor && `cursor: pointer;`}
`
