import React from 'react'
import Close from '../../assets/img/close.svg'
import { ContainerModal, ModalContent, Title } from './styles'
import { useSpring, animated } from 'react-spring'

interface ModalProps {
  title?: string
  setModal: any
  closeFunction?: Function
}

const Modal: React.FC<ModalProps> = ({ children, ...props }) => {
  const animation = useSpring({
    config: {
      duration: 300
    },
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    }
  })

  window.onmousedown = function (event: any) {
    if (event.target.id === 'container') {
      props.setModal(false)
    }
  }

  window.onkeydown = function (event: any) {
    if (event.keyCode === 27) {
      props.setModal(false)
    }
  }

  return (
    <>
      <ContainerModal id="container">
        <animated.div style={animation}>
          <ModalContent>
            <Title>
              <h1>{props.title}</h1>
              <img
                src={Close}
                alt="Fechar"
                onClick={() => {
                  props.setModal(false)
                  props.closeFunction && props.closeFunction();
                }}
              />
            </Title>
            {children}
          </ModalContent>
        </animated.div>
      </ContainerModal>
    </>
  )
}

export default Modal
