//React
import React, { useEffect } from 'react'
import Modal from '../../../../../components/Modal'
import ImgBackplate from '../../../../../components/ImgBackplate'
import { useTranslation } from 'react-i18next'

import {
  Field,
  UserComments,
  NoComment,
  Block,
  UserData,
  UserReview,
  Info
} from './styles'

interface Props {
  setModal: any
  comments: Comments[]
}

interface Comments {
  user_id: number
  text: string
  created_at: any
  User: {
    name: string
    image: string
  }
}

const ModalHistoryComments: React.FC<Props> = ({ setModal, comments }) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (comments.length) {
      var objDiv: any = document.getElementById('comments')
      objDiv.scrollTop = objDiv?.scrollHeight
    }
    //eslint-disable-next-line
  }, [])

  return (
    <Modal
      title={t('Comments')}
      setModal={() => {
        setModal(false)
      }}
    >
      <Field>
        <hr />
        {comments.length > 0 ? (
          <UserComments id="comments">
            {comments.map((element: Comments) => {
              return (
                <Block>
                  <UserData>
                    <ImgBackplate
                      image={element.User.image}
                      width="24px"
                      height="24px"
                      radius="100%"
                    />
                    <Info>
                      <h4>{element.User.name}</h4>
                      <h6>{new Date(element.created_at).toLocaleString()}</h6>
                    </Info>
                  </UserData>
                  <UserReview>
                    <h5>{element.text}</h5>
                  </UserReview>
                </Block>
              )
            })}
          </UserComments>
        ) : (
          <NoComment>{t('ModalCommentText3')}</NoComment>
        )}

        <hr />
      </Field>
    </Modal>
  )
}

export default ModalHistoryComments
