import React, { Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { AuthRoute } from "./auth.routes";
import CustomTeamCultureHistory from "../screens/Team/CustomTeamCultureHistory";

const Login = React.lazy(() => import("../screens/Login"));
const RegistrationData = React.lazy(
  () => import("../screens/RegistrationData")
);
const RatingRules = React.lazy(() => import("../screens/Rating/RatingRules"));

const RatingVoteCustom = React.lazy(() => import("../screens/Rating/RatingVoteCustom"));

const RatingVote = React.lazy(() => import("../screens/Rating/RatingVote"));

const TeamList = React.lazy(() => import("../screens/Team/TeamList/index"));
const TeamDetails = React.lazy(
  () => import("../screens/Team/TeamDetails/index")
);

const CustomTeamCultureDetails = React.lazy(
  () => import("../screens/Team/CustomTeamCultureDetails/index")
);

const EmployeeList = React.lazy(
  () => import("../screens/Employee/EmployeeList")
);
const EmployeeDetails = React.lazy(
  () => import("../screens/Employee/EmployeeDetails")
);
const Welcome = React.lazy(() => import("../screens/Welcome"));

const VacancyList = React.lazy(() => import("../screens/Vacancy/VacancyList"));
const VacancyDetails = React.lazy(
  () => import("../screens/Vacancy/VacancyDetails")
);
const VacancyApplicationDetails = React.lazy(
  () => import("../screens/Vacancy/VacancyApplicationDetails")
);

const CandidateInvite = React.lazy(() => import("../screens/Candidate/Invite"));

const CandidateApplicationList = React.lazy(
  () => import("../screens/Candidate/ApplicationList")
);

const CandidateSendApplication = React.lazy(
  () => import("../screens/Candidate/SendApplication")
);

const PassRecoverSentEmail = React.lazy(
  () => import("../screens/Login/utils/RecoverPassSendEmail")
);

const PassRecoverChange = React.lazy(
  () => import("../screens/Login/utils/RecoverPassChange")
);

const ExitRating = React.lazy(() => import("../screens/Employee/ExitRating"));

const FormExitRating = React.lazy(() => import("../screens/Employee/FormExitRating"));

const TeamHistory = React.lazy(() => import("../screens/Team/TeamHistory"));

const TeamHistoryDetails = React.lazy(
  () => import("../screens/Team/TeamHistoryDetails")
);

const CustomTeamCultureHistoryDetails = React.lazy(
  () => import("../screens/Team/CustomTeamCultureHistoryDetails")
)

const UseTerms = React.lazy(() => import("../screens/Login/utils/UseTerms"));

const Report = React.lazy(() => import("../screens/Report"));

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<></>}>
        <Switch>
          <Route
            path="/invite/candidate/team/:team_id/vacancie/:vacancie_id"
            component={CandidateInvite}
          />

          <Route path="/login" exact component={Login} />

          <Route path="/terms" component={UseTerms} />

          <Route path="/pass/recover/email" component={PassRecoverSentEmail} />

          <Route path="/pass/recover/change" component={PassRecoverChange} />

          <AuthRoute path="/registrationdata" component={RegistrationData} />

          <Route path="/employee/details/:id/exit" component={ExitRating} />

          <Route path="/employee/details/:id/formexit" component={FormExitRating} />

          <AuthRoute path="/employee/details/:id" component={EmployeeDetails} />

          <AuthRoute path="/employee/" component={EmployeeList} />

          <AuthRoute
            path="/team/details/:id/rating/:rating_id/vote/custom/:custom_id"
            component={RatingVoteCustom}
          />

          <AuthRoute
            path="/team/details/:id/rating/:rating_id/vote"
            component={RatingVote}
          />

          <AuthRoute
            path="/team/details/:id/rating/:rating_id"
            component={RatingRules}
          />

          <AuthRoute path="/team/details/:id/history/details/:rating_id/CustomTeamCulture" component={CustomTeamCultureHistoryDetails} />
          <AuthRoute path="/team/details/:id/history/details/:rating_id" component={TeamHistoryDetails} />

          <AuthRoute path="/team/details/:id/history/CustomTeamCulture" component={CustomTeamCultureHistory} />
          <AuthRoute path="/team/details/:id/history" component={TeamHistory} />

          <AuthRoute path="/team/details/:id/CustomTeamCulture" component={CustomTeamCultureDetails} />
          <AuthRoute path="/team/details/:id" component={TeamDetails} />

          <AuthRoute path="/team" component={TeamList} />

          <AuthRoute path="/welcome" component={Welcome} />

          <AuthRoute
            path="/vacancy/details/:id/candidate/:candidate_id"
            component={VacancyApplicationDetails}
          />

          <AuthRoute path="/vacancy/details/:id" component={VacancyDetails} />

          <AuthRoute path="/vacancy" component={VacancyList} />

          <AuthRoute
            path="/candidate/details/:id"
            component={CandidateSendApplication}
          />

          <AuthRoute path="/candidate" component={CandidateApplicationList} />

          <AuthRoute path="/report" component={Report} />

          <Route path="*" render={() => <Redirect to="/login" />} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routes;
