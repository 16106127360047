import styled, { keyframes } from 'styled-components';

export interface ConfettiPieceProps {
    color: string,
    duration: number,
    left: number,
    delay: number
}

const fall = keyframes`
  0% { transform: translateY(-100%) rotate(0deg); opacity: 1; }
  100% { transform: translateY(100vh) rotate(720deg); opacity: 0; }
`;

export const ConfettiPiece = styled.div<ConfettiPieceProps>`
  position: absolute;
  top: 0;
  width: 10px;
  height: 30px;
  background-color: ${({ color }) => color};
  animation: ${fall} ${({ duration }) => duration}s linear infinite;
  left: ${({ left }) => left}px;
  animation-delay: ${({ delay }) => delay}s;
`;

export const ConfettiContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1001; /* Aumentando o z-index para que fique acima do fundo branco */
`;

