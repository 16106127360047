import React, { createContext, useCallback, useContext, useState } from 'react'
import ToastContainer from '../components/ToastContainer'
import { v4 as uuid } from 'uuid'

interface ToastContextData {
  addToast(message: Omit<ToastMassege, 'id'>): void
  removeToast(id: string): void
}

export interface ToastMassege {
  id: string
  type?: 'success' | 'error' | 'info'
  title: string
  description?: string
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData)

export const ToastProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<ToastMassege[]>([])

  const addToast = useCallback(
    ({ title, description, type }: Omit<ToastMassege, 'id'>) => {
      const id = uuid()

      const toast = {
        id,
        type,
        description,
        title
      }

      setMessages(state => [...state, toast])
    },
    []
  )
  const removeToast = useCallback((id: string) => {
    setMessages(state => state.filter(message => message.id !== id))
  }, [])

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      {messages.length > 0 ? <ToastContainer messages={messages} /> : null}
    </ToastContext.Provider>
  )
}

export function useToast() {
  const context = useContext(ToastContext)

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider')
  }

  return context
}
