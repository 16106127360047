import React from 'react'
import { Loader, LoadingContainer } from './styles'

const ModalLoading: React.FC = props => {
  return (
    <LoadingContainer>
      <Loader />
    </LoadingContainer>
  )
}

export default ModalLoading
