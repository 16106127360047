import React from "react";
import { Loader, LoadingContainer } from "./styles";

const Loading: React.FC = (props) => {
  return (
    <LoadingContainer>
      <Loader />
    </LoadingContainer>
  );
};

export default Loading;
