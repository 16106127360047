//React
import { ConfettiContainer, ConfettiPiece } from "./styles";

interface Props {
  count: number
}

const generateConfetti = (count: number) => {
  const colors = ['var(--color-orange)'];
  const confetti = [];

  for (let i = 0; i < count; i++) {
    const left = Math.random() * window.innerWidth;
    const duration = Math.random() * 3 + 2; // 2 to 5 seconds
    const delay = Math.random() * 0; // No delay
    const color = colors[Math.floor(Math.random() * colors.length)];
    confetti.push({ left, duration, delay, color });
  }

  return confetti;
};

const ConfettiAnimation: React.FC<Props> = ({ count = 100 }) => {
  const confettiPieces = generateConfetti(count);

  return (
    <ConfettiContainer>
      {confettiPieces.map((confetti, index) => (
        <ConfettiPiece
          key={index}
          left={confetti.left}
          duration={confetti.duration}
          delay={confetti.delay}
          color={confetti.color}
        />
      ))}
    </ConfettiContainer>
  );
};

export default ConfettiAnimation;
