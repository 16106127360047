import styled from 'styled-components'

import { Props } from './index'

export const Container = styled.button<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  //mudar a cor do texto
  ${({ color }) => color && `color: ${color};`}

  // se background exister e icone não pinta o background
  ${({ backgroundColor, icon }) =>
    backgroundColor &&
    !icon &&
    `background-color:${backgroundColor}; border: 2px solid ${backgroundColor};`}

   // se radius existir adiciona a borda redonda
  ${({ radius }) => radius && `border-radius: ${radius};`}


  ${({ padding }) => padding && `padding: ${padding};`}
   
  ${({ margin }) => margin && `margin:${margin}; `}

  ${({ width }) => width && `width: ${width};`}

  ${({ height }) => height && `height: ${height};`}

  ${({ gap }) => gap && `gap: ${gap};`}


  ${({ fontSize }) =>
    fontSize &&
    ` > h4 {
    font-size: ${fontSize};
  }`}

  // se outline existir
  ${({ outlined, backgroundColor }) =>
    outlined &&
    `background-color: transparent; 
     color: ${backgroundColor};
  `}
  // se icone existir adiciona uma imagem de fundo 
  ${({ icon }) =>
    icon &&
    `
    >img{
      width: 100%;
      height: 100%;
    } 
    border:none;
    background-color: transparent;
    padding:0px;
  `}

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    transition: 0.25;
    opacity: 0.7;
  }
`
