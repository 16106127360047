//React
import React, { useEffect } from 'react'
import { useToast } from '../../../../hooks/toast'
import { useTranslation } from 'react-i18next'
import ConfettiAnimation from '../../../ConfettiAnimation'
import MessageAnimation from '../../../MessageAnimation'
import api from '../../../../services/api'

export interface RatingEndNotification {
  id: Number,
  team_name: string,
  percent: Number,
  key_name: string
}

interface Props {
  setModal: Function
  ratingEndNotification: RatingEndNotification
}

const ModalRatingEndNotifications: React.FC<Props> = ({ setModal, ratingEndNotification }) => {
  const { addToast } = useToast()
  const { t } = useTranslation()

  async function deleteRatingEndNotifications(id: Number) {
    await api
      .delete(`/notification/deleteRatingEndNotifications/${id}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('@token/iteams')
        }
      })
      .then((res) => {

      })
      .catch((err) => {
        if (err.response) {
          addToast({
            type: err.response.data.Toast.type,
            title: t(err.response.data.Toast.title),
            description: t(err.response.data.Toast.description)
          })
        } else {
          addToast({
            type: 'error',
            title: t('Server'),
            description: t('CommunicationError')
          })
        }
      })
  }

  const handleClose = () => {
    deleteRatingEndNotifications(ratingEndNotification.id);

    setModal(false);
  }

  useEffect(() => {
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <ConfettiAnimation count={28} />
      <MessageAnimation
        title={''}
        onClose={handleClose}
        children={
          <>
            <h1>{t('YourTeam')} {ratingEndNotification.team_name} {t('HasCompleted')} {ratingEndNotification.percent}% {t('HighPerformanceIndicatorCycle')} <b>{t(`${ratingEndNotification.key_name}WithoutNumber`)}</b>!</h1>
            <h2>{t('RatingEndCelebrationMessage')}</h2>
          </>
        }
      />
    </>
  )
}

export default ModalRatingEndNotifications
