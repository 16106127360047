import React from 'react'

import { Container } from './styles'
import colors from '../../vars/colors'

export interface Props {
  /**
   *  Cor do background
   */
  backgroundColor?: string
  /**
   *  Cor do texto
   */
  color?: string
  /**
   *  Título do botão
   */
  title?: string
  /**
   *  Botão com estilo outline
   */
  outlined?: boolean
  /**
   *  Aplica a propriedade Border-Radius
   */
  radius?: string
  /**
   *  Aplica  apropriedade  Padding
   */
  padding?: string
  /**
   *  Aplica  apropriedade  Margin
   */
  margin?: string

  /**
   *  Icone para o botão
   */
  icon?: any
  /**
   *  Desativa o botão
   */
  disabled?: boolean
  /**
   *  Chama uma função ao clicar
   */
  onClick?: () => void
  /**
   *  Evita o erro do typescript quando utilizado  type="submit"
   */
  type?: any

  width?: string

  height?: string

  gap?: string

  fontSize?: string
}

const Button: React.FC<Props> = ({
  icon,
  title,
  margin,
  width,
  height,
  gap,
  fontSize,
  backgroundColor = colors.buttonPrimary,
  color = colors.buttonText,
  outlined = false,
  radius = '10px',
  padding = '12px 24px',

  ...rest
}) => {
  return (
    <Container
      icon={icon}
      backgroundColor={backgroundColor}
      color={color}
      outlined={outlined}
      margin={margin}
      radius={radius}
      padding={padding}
      width={width}
      height={height}
      gap={gap}
      fontSize={fontSize}
      {...rest}
    >
      {title && <h4>{title}</h4>}
      {icon && <img src={icon} alt="Icon" />}
    </Container>
  )
}

export default Button
