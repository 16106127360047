import styled from 'styled-components'

export const Loader = styled.div`
  border: 6px solid var(--color-white);
  border-top: 6px solid var(--color-orange);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 0.5s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -30px;
  margin-left: -30px;

  @media (max-width: 768px) {
    margin-top: 0px;
    margin-left: 0px;
  }
`
