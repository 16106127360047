//React
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import Modal from '../../../Modal'
import ImgBackplate from '../../../ImgBackplate'
import api from '../../../../services/api'
import ModalLoading from '../../../ModalLoading'
import { useToast } from '../../../../hooks/toast'
import ReactTooltip from 'react-tooltip'
import finishStar from '../../../../assets/icons/finishstar.svg'
import { useTranslation } from 'react-i18next'

import {
  Field,
  UserComments,
  Block,
  UserData,
  UserReview,
  Info,
  SetAllRead,
  SetRead,
  UserInfo,
  NoData,
  LoadingContainer
} from './styles'

interface Props {
  setModal: any
}

interface Notifications {
  id: number
  notification_type: string
  user_id: number
  viewed: boolean
  vacancie_notification_id?: number

  VacancieNotification?: {
    Team: {
      name: string
      image: string
    }
    description: string
    team_id: number
    created_at: any
  }

  team_notification_id?: number

  TeamNotification?: {
    Team: {
      name: string
      image: string
    }
    description: string
    team_id: number
    created_at: any
  }

  DailyNotification?: {
    DailyPhrase: {
      phrase: string
    }
    daily_number: number
    created_at: any
    description: string
  }
}

const ModalNotifications: React.FC<Props> = ({ setModal }) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [page, setPage] = useState<number>(1)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [onQuery, setOnQuery] = useState<boolean>(false)
  const [notifications, setNotifications] = useState<Notifications[]>([])
  const { addToast } = useToast()
  const { t } = useTranslation()

  async function markViewed(notificationId: number) {
    await api
      .put(
        `/notification/${notificationId}/mark/viewed`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('@token/iteams')
          }
        }
      )
      .then(() => {
        // addToast({
        //   type: res.data.Toast.type,
        //   title: t(res.data.Toast.title),
        //   description: t(res.data.Toast.description)
        // })
      })
      .catch((err) => {
        if (err.response) {
          addToast({
            type: err.response.data.Toast.type,
            title: t(err.response.data.Toast.title),
            description: t(err.response.data.Toast.description)
          })
        } else {
          addToast({
            type: 'error',
            title: t('Server'),
            description: t('CommunicationError')
          })
        }
      })
  }

  async function markAllViewed() {
    setOnQuery(true)
    await api
      .put(
        `/notification/mark/all/viewed`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('@token/iteams')
          }
        }
      )
      .then((res) => {
        getNotifications()
        setHasMore(true)
        setPage(1)
        scrollTop()
        setOnQuery(false)
        getNotifications()

        // addToast({
        //   type: res.data.Toast.type,
        //   title: t(res.data.Toast.title),
        //   description: t(res.data.Toast.description)
        // })
      })
      .catch((err) => {
        if (err.response) {
          addToast({
            type: err.response.data.Toast.type,
            title: t(err.response.data.Toast.title),
            description: t(err.response.data.Toast.description)
          })
        } else {
          addToast({
            type: 'error',
            title: t('Server'),
            description: t('CommunicationError')
          })
        }
      })
  }

  async function getNotifications() {
    await api
      .get(`/notification/list/all?page=${1}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('@token/iteams')
        }
      })
      .then((res) => {
        setNotifications(res.data)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        if (err.response) {
          addToast({
            type: err.response.data.Toast.type,
            title: t(err.response.data.Toast.title),
            description: t(err.response.data.Toast.description)
          })
        } else {
          addToast({
            type: 'error',
            title: t('Server'),
            description: t('CommunicationError')
          })
        }
      })
  }

  async function fetchData() {
    let fetchedpage
    setPage((state) => {
      return (fetchedpage = state + 1)
    })

    await api
      .get(`/notification/list/all?page=${fetchedpage}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('@token/iteams')
        }
      })
      .then((res) => {
        if (res.data.length > 0) {
          setNotifications([...notifications, ...res.data])
        } else {
          setHasMore(false)
        }
      })
  }

  function scrollTop() {
    var objDiv: any = document.getElementById('scroll')
    objDiv.scrollTop = -objDiv?.scrollHeight
  }

  useEffect(() => {
    getNotifications()
    //eslint-disable-next-line
  }, [])

  return (
    <Modal
      title={t('Notifications')}
      setModal={() => {
        setModal(false)
      }}
    >
      {loading ? (
        <LoadingContainer>
          <ModalLoading />
        </LoadingContainer>
      ) : (
        <Field>
          <SetAllRead style={{ pointerEvents: onQuery ? 'none' : 'auto' }}>
            {notifications.length > 0 && (
              <h5
                onClick={() => {
                  markAllViewed()
                }}
              >
                {t('MarkAllRead')}
              </h5>
            )}
          </SetAllRead>

          <hr />
          <UserComments id="scroll">
            {notifications.length > 0 ? (
              <>
                <InfiniteScroll
                  scrollableTarget={'scroll'}
                  dataLength={page * 10}
                  next={fetchData}
                  hasMore={hasMore}
                  loader={
                    <h5 style={{ opacity: '0.5', textAlign: 'center' }}>
                      {t('Loading')}
                    </h5>
                  }
                >
                  {notifications.map((element, i: number) => {
                    return (
                      <Block
                        key={element.id}
                        style={{ opacity: element.viewed ? '0.5' : '1' }}
                      >
                        {element.notification_type === 'team' && (
                          <>
                            <UserData>
                              <UserInfo>
                                <ImgBackplate
                                  image={element.TeamNotification?.Team.image}
                                  width="24px"
                                  height="24px"
                                  radius="100%"
                                />
                                <Info>
                                  <h4>{element.TeamNotification?.Team.name}</h4>
                                  <h6>
                                    {new Date(
                                      element.TeamNotification?.created_at
                                    ).toLocaleString()}
                                  </h6>
                                </Info>
                              </UserInfo>
                              <SetRead
                                style={{
                                  pointerEvents: onQuery ? 'none' : 'auto'
                                }}
                                onClick={() => {
                                  markViewed(element.id)
                                  notifications[i].viewed = !element.viewed
                                  setNotifications([...notifications])
                                }}
                                className="border"
                                data-tip={
                                  element.viewed
                                    ? t('MarkRead')
                                    : t('MarkUnread')
                                }
                                data-for="notification"
                              >
                                {element.viewed ? (
                                  <div className="read"></div>
                                ) : (
                                  <div className="notread"></div>
                                )}
                              </SetRead>
                            </UserData>

                            <UserReview>
                              {element.TeamNotification?.description
                                .split('/')
                                .map((e, i: number) => {
                                  return (
                                    <h5
                                      style={{
                                        marginLeft: i !== 0 ? '3px' : 0
                                      }}
                                    >
                                      {t(e)}
                                    </h5>
                                  )
                                })}
                            </UserReview>
                          </>
                        )}

                        {element.notification_type === 'vacancie' && (
                          <>
                            <UserData>
                              <UserInfo>
                                <ImgBackplate
                                  image={
                                    element.VacancieNotification?.Team.image
                                  }
                                  width="24px"
                                  height="24px"
                                  radius="100%"
                                />
                                <Info>
                                  <h4>
                                    {element.VacancieNotification?.Team.name}
                                  </h4>
                                  <h6>
                                    {new Date(
                                      element.VacancieNotification?.created_at
                                    ).toLocaleString()}
                                  </h6>
                                </Info>
                              </UserInfo>
                              <SetRead
                                style={{
                                  pointerEvents: onQuery ? 'none' : 'auto'
                                }}
                                onClick={() => {
                                  markViewed(element.id)
                                  notifications[i].viewed = !element.viewed
                                  setNotifications([...notifications])
                                }}
                                className="border"
                                data-tip={
                                  element.viewed
                                    ? t('MarkRead')
                                    : t('MarkUnread')
                                }
                                data-for="notification"
                              >
                                {element.viewed ? (
                                  <div className="read"></div>
                                ) : (
                                  <div className="notread"></div>
                                )}
                              </SetRead>
                            </UserData>

                            <UserReview>
                              {element.VacancieNotification?.description
                                .split('/')
                                .map((e, j: number) => {
                                  return (
                                    <h5
                                      style={{
                                        marginLeft: j !== 0 ? '3px' : 0
                                      }}
                                    >
                                      {t(e)}
                                    </h5>
                                  )
                                })}
                            </UserReview>
                          </>
                        )}

                        {element.notification_type === 'daily' && (
                          <>
                            <UserData>
                              <UserInfo>
                                <ImgBackplate
                                  image={finishStar}
                                  width="24px"
                                  height="24px"
                                  radius="100%"
                                />
                                <Info>
                                  <h4>{t('DailyPhrase')}</h4>
                                  <h6>
                                    {new Date(
                                      element.DailyNotification?.created_at
                                    ).toLocaleString()}
                                  </h6>
                                </Info>
                              </UserInfo>
                              <SetRead
                                style={{
                                  pointerEvents: onQuery ? 'none' : 'auto'
                                }}
                                onClick={() => {
                                  markViewed(element.id)
                                  notifications[i].viewed = !element.viewed
                                  setNotifications([...notifications])
                                }}
                                className="border"
                                data-tip={
                                  element.viewed
                                    ? t('MarkRead')
                                    : t('MarkUnread')
                                }
                                data-for="notification"
                              >
                                {element.viewed ? (
                                  <div className="read"></div>
                                ) : (
                                  <div className="notread"></div>
                                )}
                              </SetRead>
                            </UserData>

                            <UserReview>
                              <h5>
                                {t(
                                  element.DailyNotification
                                    ?.description as string
                                )}
                              </h5>
                            </UserReview>
                          </>
                        )}
                      </Block>
                    )
                  })}
                  <ReactTooltip
                    className="tooltip"
                    place="left"
                    backgroundColor="#ff3800"
                    textColor="white"
                    id="notification"
                    effect="solid"
                  />
                </InfiniteScroll>
              </>
            ) : (
              <NoData>
                <h4>{t('NoNotifications')}</h4>
              </NoData>
            )}
          </UserComments>

          <hr />
        </Field>
      )}
    </Modal>
  )
}

export default ModalNotifications
