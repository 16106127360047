import { ThemeProvider } from "styled-components";
import GlobalStyle from "./styles/global";
import theme from "./styles/theme";
import AppProvider from "./hooks";
import Routes from "./routes/routes";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <Routes />
      </AppProvider>
      <GlobalStyle />
    </ThemeProvider>
  );
}

export default App;
