import { useTranslation } from 'react-i18next'
const Sidebar = () => {
  const { t } = useTranslation()
    return (
        <>
            <h3 style={{ position: 'relative', color: 'grey', marginTop: 'auto', marginLeft: 'auto', marginRight: 'auto' }}>® <span>{t('RightsReserved')}</span></h3>
        </>
    )
}

export default Sidebar;