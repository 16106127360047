import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  padding: 30px;
  overflow: hidden;
  z-index: 9999;
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    margin-top: 73px;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
