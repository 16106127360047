import styled from 'styled-components'

export const Field = styled.div`
  height: 100%;
  width: 100%;

  .tooltip {
    font-size: 12px !important;
    padding: 0 6px !important;
  }

  > hr {
    opacity: 0.5;
  }

  @media (max-width: 768px) {
    height: auto;
  }
`
export const SetAllRead = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 15px;
  > h5 {
    opacity: 0.7;
    cursor: pointer;
    width: fit-content;
    &:hover {
      transition: 0.25;
      opacity: 0.4;
    }
  }
`

export const SetRead = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border-radius: 100%;

  .read {
    width: 6px;
    height: 6px;
    background-color: transparent;
  }

  .notread {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: blue;
  }

  &:hover {
    background-color: #00000033;
  }
`

export const UserInfo = styled.div`
  display: flex;
  gap: 6px;
`

export const UserComments = styled.div`
  padding-right: 12px;
  max-height: 50vh;
  min-height: fit-content;
  overflow-y: auto;
  width: 100%;
  > h4 {
    padding-top: 6px;
  }

  @media (max-width: 768px) {
    max-height: 80vh;
    min-height: 80vh;
  }
`

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  margin: 6px 0;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;

  &:hover {
    background-color: #0000001a;
    border-radius: 8px;
    .border {
      border: 1px solid #00000080;
    }
  }
`

export const UserData = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 6px;
  width: 100%;

  > h4 {
    width: 100%;
  }

  > h6 {
    opacity: 0.5;
    margin-bottom: 6px;
  }
`

export const Info = styled.div`
  > h6 {
    opacity: 0.5;
    margin-bottom: 6px;
  }
`

export const UserReview = styled.div`
  margin-left: 30px;
  line-height: 14px;

  > h5 {
    display: inline;
  }
`

export const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  max-height: 50vh;
  min-height: 50vh;
  @media (max-width: 768px) {
    max-height: 80vh;
    min-height: 80vh;
  }
`
export const LoadingContainer = styled.div`
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`
