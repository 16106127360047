import styled from "styled-components";

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(128, 128, 128, 0.6);
  display: flex;
  flex-direction: column;
  z-index: 10;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  position: fixed;
  overflow-y: scroll;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    padding: 0;
    width: 100vw;
  }

  scrollbar-width: none;
  scrollbar-color: transparent;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

export const ModalContent = styled.div`
  animation: slide-top 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  @keyframes slide-top {
    0% {
      transform: translateY(100vh);
    }
    100% {
      transform: translateY(0);
    }
  }

  width: 460px;
  padding: 24px 30px;
  background-color: var(--color-white);
  border-radius: 10px;
  z-index: 11;
  display: flex;
  flex-direction: column;
  gap: 1em;

  @media only screen and (max-width: 768px) {
    width: 100vw;
    min-height: 100vh;
    border-radius: 0px;
    overflow-y: scroll;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > h1 {
    color: var(--color-orange);
  }

  > img {
    width: 44px;
    height: 44px;
    cursor: pointer;

    :hover {
      opacity: 0.5;
    }
  }
`;
