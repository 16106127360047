import styled from 'styled-components'
import close from '../../assets/img/close.svg'
import MainLogo from '../../assets/img/logosidebar.png'

export const Container = styled.div`
  background-color: var(--color-background);
  z-index: 8; // coloquei para alguns botoes se ajeitarem
`
export const Header = styled.div<{ isOpened: boolean }>`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  @media (min-width: 768px) {
    display: none;
  }
`

export const ContainerNav = styled.div<{ isOpened: boolean }>`
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  top: 0;
  border-radius: 0px 20px 20px 0px;
  padding-top: 22px;
  width: 86px;
  background-color: var(--color-white);
  z-index: 9;
  min-height: 400px;
  > ul {
    list-style-type: none;
  }

  @media (max-width: 768px) {
    transform: ${({ isOpened }) =>
      isOpened ? 'translateX(0)' : 'translateX(-96px)'};
    transition: 0.25s;
    position: fixed;
  }
`

export const Logo = styled.image<{ isOpened?: boolean }>`
  display: flex;
  content: url(${MainLogo});
  width: 70px;
  height: 25px;

  :last-child {
    content: url(${MainLogo});
    transform: none;
    width: 70px;
    height: 25px;
  }

  @media (max-width: 768px) {
    content: url(${close});
    width: 36px;
    height: 36px;
    transform: ${({ isOpened }) =>
      isOpened ? 'rotate(0deg)' : 'rotate(-180deg)'};
    transition: 0.5s;
  }
`
export const Icon = styled.div<{ hasNotification?: boolean }>`
  height: 26px;
  margin-bottom: 1px;

  ${({ hasNotification }) =>
    hasNotification &&
    `&.notification {
      animation: wobble-hor-top 0.8s both;
   
    @keyframes wobble-hor-top {
      0%,
      100% {
        transform: translateX(0%);
        transform-origin: 50% 50%;
      }
      15% {
        transform: translateX(-1px) rotate(6deg);
      }
      30% {
        transform: translateX(1px) rotate(-6deg);
      }
      45% {
        transform: translateX(-1px) rotate(3.6deg);
      }
      60% {
        transform: translateX(1px) rotate(-2.4deg);
      }
      75% {
        transform: translateX(-1px) rotate(1.2deg);
      }
    }

    `};

  > img {
    width: 26px;
    height: 26px;
  }

  .count {
    background-color: var(--color-blue-confirm);
    color: white;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -20px;
    margin-left: 12px;
    position: absolute;
    overflow: hidden;
  }
`

export const IconContainer = styled.div<{ selected?: boolean }>`
  margin-bottom: 24px;
  cursor: pointer;

  opacity: ${({ selected }) => (selected ? '1' : '0.5')};

  :hover {
    opacity: 1;
    transition: 0.25s;
  }

  > li a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-orange);
  }
`

export const Line = styled.div`
  width: 100%;
  padding: 22px 13px 22px 13px;

  > hr {
    border-top: 1px solid var(--color-black);
    opacity: 0.2;
  }
`

export const Hamburguer = styled.div<{ isOpened: boolean }>`
  margin-left: 20px;
  transform: ${({ isOpened }) =>
    isOpened ? 'rotate(270deg)' : 'rotate(0deg)'};
  transition: 0.5s;

  > img {
    width: 36px;
    height: 36px;
  }
`

export const Exit = styled.div`
  height: 100vh;
  display: flex;
  align-items: flex-end;

  > a {
    opacity: 0.5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-orange);
    transition: 0.25s;

    :hover {
      opacity: 1;
    }
  }
`
export const ExitContainer = styled.div<{ isOpened: boolean }>`
  width: 100%;
  height: 100vh;

  background-color: var(--color-black);
  position: fixed;

  opacity: 0.2;
  display: ${({ isOpened }) => (isOpened ? 'flex' : 'none')};
`

export const LogoMobileContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-left: -56px;
`

export const NotificationButton = styled.div<{ hasNotification: boolean }>`
  opacity: ${({ hasNotification }) => (hasNotification ? '1' : '0.5')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 24px;

  > h6 {
    color: var(--color-orange);
  }

  :hover {
    opacity: 1;
    transition: 0.25s;
  }
`
