import styled, { keyframes } from 'styled-components';

const grow = keyframes`
  0% { transform: scale(0); }
  100% { transform: scale(1); }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1000;
`;

export const MessageText = styled.h1`
  font-size: 4rem;
  color: #FF0A54;
  animation: ${grow} 1s ease-in-out;
`;

export const MessageContainerHeader = styled.div`
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 30px;
`;

export const MessageContainerMain = styled.div`
  height: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MessageContainerContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 100px 200px;
  animation: ${grow} 1s ease-in-out;
  height: calc(100vh - 90px);
  
  > h1 {
    text-align: center;
    font-size: 3rem;
    color: var(--color-orange);
    line-height: 1.4;
  }

  > h2 {
    text-align: center;
    font-size: 2rem;
    margin-top: 50px;
    line-height: 1.2;
  }
`;