import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import exit from '../../assets/img/Sair.svg'
import hamburguer from '../../assets/img/hamburguer.svg'
import { useTranslation } from 'react-i18next'

import ImgBackplate from '../ImgBackplate'

import ModalNotifications from './utils/ModalNotifications'
import Notification from '../../assets/icons/notification.svg'

import DadosIcon from '../../assets/img/dadoscadastrais.svg'
import EmployeesIcon from '../../assets/img/Employees.svg'
import PieChartIcon from '../../assets/icons/pie_chart.svg'
import Welcome from '../../assets/icons/welcome.svg'
import Search from '../../assets/icons/orangeSearch.svg'
import Candidate from '../../assets/icons/candidate.svg'
import Report from '../../assets/icons/report-icon.svg'

import { useToast } from '../../hooks/toast'

import {
  Container,
  Logo,
  Icon,
  Line,
  IconContainer,
  Hamburguer,
  ContainerNav,
  Header,
  Exit,
  LogoMobileContainer,
  NotificationButton,
  ExitContainer
} from './styles'
import api from '../../services/api'
import ModalRatingEndNotifications, { RatingEndNotification } from './utils/ModalRatingEndNotifications'

const Sidebar: React.FC = () => {
  const [isOpened, setIsOpened] = useState<boolean>(false)
  const [modalNotificationsOpen, setModalNotificationsOpen] =
    useState<boolean>(false)
  const [hasData, setHasData] = useState<boolean>(false)
  const [ratingEndNotification, setRatingEndNotification] = useState<RatingEndNotification>();
  const [modalRatingEndNotification, setModalRatingEndNotification] = useState<boolean>(false);
  const { addToast } = useToast()
  const { t } = useTranslation()

  const SidebarData = [
    {
      icon: Candidate,
      link: '/candidate',
      name: t('Candidate'),
      candidate: true
    },
    {
      icon: Welcome,
      link: '/welcome',
      name: t('Presentation')
    },
    {
      icon: PieChartIcon,
      link: '/team',
      name: t('Team')
    },
    {
      icon: Search,
      link: '/vacancy',
      name: t('Recruiting')
    },
    {
      icon: EmployeesIcon,
      link: '/employee',
      name: t('Employees'),
      restricted: true
    },
    {
      icon: Report,
      link: '/report',
      name: t('Reports')
    },
    {
      icon: DadosIcon,
      link: '/registrationdata',
      name: t('Settings')
    }
  ]

  // eslint-disable-next-line
  async function checkNotifications() {
    await api
      .get(`/check/notifications`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('@token/iteams')
        }
      })
      .then((res) => {
        localStorage.setItem('@notificationCount/iteams', res.data)
        setHasData(res.data > 0 ? true : false)
      })
      .catch((err) => {
        if (err.response) {
          addToast({
            type: err.response?.data?.Toast?.type,
            title: t(err.response?.data?.Toast?.title),
            description: t(err.response?.data?.Toast?.description)
          })
        } else {
          addToast({
            type: 'error',
            title: t('Server'),
            description: t('CommunicationError')
          })
        }
      })
  }

  async function getRatingEndNotifications() {
    await api
      .get(`/notification/listRatingEndNotifications`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('@token/iteams')
        }
      })
      .then((res) => {
        if (res.data?.id) {
          setModalRatingEndNotification(true)
          setRatingEndNotification(res.data as RatingEndNotification);
        }
      })
      .catch((err) => {
        if (err.response) {
          addToast({
            type: err.response.data.Toast.type,
            title: t(err.response.data.Toast.title),
            description: t(err.response.data.Toast.description)
          })
        } else {
          addToast({
            type: 'error',
            title: t('Server'),
            description: t('CommunicationError')
          })
        }
      })
  }

  useEffect(() => {
    if (localStorage.getItem('@role/iteams') !== 'candidate') {
      checkNotifications()
      getRatingEndNotifications()
    }
    // eslint-disable-next-line
  }, [modalNotificationsOpen])

  return (
    <>
      {modalNotificationsOpen && (
        <ModalNotifications setModal={setModalNotificationsOpen} />
      )}

      {modalRatingEndNotification && ratingEndNotification && (
        <ModalRatingEndNotifications
          setModal={setModalRatingEndNotification}
          ratingEndNotification={ratingEndNotification}
        />
      )}

      <Container>
        <ExitContainer isOpened={isOpened} onClick={() => setIsOpened(false)} />
        <Header isOpened={isOpened}>
          <Hamburguer isOpened={isOpened} onClick={() => setIsOpened(true)}>
            <img src={hamburguer} alt="" />
          </Hamburguer>
          <LogoMobileContainer>
            <Logo />
          </LogoMobileContainer>
        </Header>

        <ContainerNav isOpened={isOpened}>
          <Logo onClick={() => setIsOpened(false)} isOpened={isOpened} />
          <Line>
            <hr />
          </Line>
          <ul>
            {localStorage.getItem('@role/iteams') !== 'candidate' ? (
              <>
                <NotificationButton
                  hasNotification={
                    Number(localStorage.getItem('@notificationCount/iteams')) >
                    0 || hasData
                  }
                  onClick={() => {
                    setModalNotificationsOpen(true)
                  }}
                >
                  <Icon
                    hasNotification={
                      Number(
                        localStorage.getItem('@notificationCount/iteams')
                      ) > 0 || hasData
                    }
                    className="notification"
                  >
                    <img src={Notification} alt="" />

                    {(Number(
                      localStorage.getItem('@notificationCount/iteams')
                    ) > 0 ||
                      hasData) && (
                        <div className="count">
                          <h5>
                            {localStorage.getItem('@notificationCount/iteams')}
                          </h5>
                        </div>
                      )}
                  </Icon>
                  <h6>{t('Notifications')}</h6>
                </NotificationButton>
                {SidebarData.map((value, key) => {
                  if (
                    (localStorage.getItem('@role/iteams') !== 'admin' &&
                      value.restricted) ||
                    value.candidate
                  ) {
                    return null
                  }

                  return (
                    <IconContainer
                      selected={
                        window.location.pathname.startsWith(value.link) && true
                      }
                      key={key}
                    >
                      <li>
                        <Link to={value.link}>
                          <Icon>
                            <img src={value.icon} alt="" />
                          </Icon>
                          <h6>{value.name}</h6>
                        </Link>
                      </li>
                    </IconContainer>
                  )
                })}
              </>
            ) : (
              <>
                {SidebarData.map((value, key) => {
                  if (!value.candidate && value.name !== t('Settings')) {
                    return null
                  }

                  return (
                    <IconContainer
                      selected={
                        window.location.pathname.startsWith(value.link) && true
                      }
                      key={key}
                    >
                      <li>
                        <Link to={value.link}>
                          <Icon>
                            <img src={value.icon} alt="" />
                          </Icon>
                          <h6>{value.name}</h6>
                        </Link>
                      </li>
                    </IconContainer>
                  )
                })}
              </>
            )}
          </ul>
          <Exit>
            <Link to="/login">
              <ImgBackplate image={exit} width={'26px'} height={'26px'} />
              <h6 style={{ marginTop: '1px', marginBottom: '12px' }}>Logout</h6>
            </Link>
          </Exit>
        </ContainerNav>
      </Container>
    </>
  )
}

export default Sidebar
